import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Context } from '../filters/Store'

export default function OS (
  { name }) {
  const [state] = useContext(Context)
  const type = 'os'
  const color = 'var(--sec-dragon)'

  const divStyle = state.highlight &&
    state.categories[type].children[name] && state.categories[type].children[name].selected
    ? {
        color: color,
        fontWeight: 'bold',
        fontSize: '1.2em',
        marginLeft: '0.1em',
        marginRight: '0.1em'
      }
    : {}

  const label = state.categories[type].children[name] ? state.categories[type].children[name].label : name

  return (
    <span style={divStyle}>{label}</span>)
}

OS.propTypes = {
  name: PropTypes.string
}
