
const Reducer = (state, action) => {
  function toggleElement (category, selector) {
    const selected = !state.categories[category].children[selector].selected
    return {
      ...state.categories[category].children,
      [selector]: {
        ...state.categories[category].children[selector],
        selected: selected
      }
    }
  }

  function toggleCategory (selector) {
    const selected = !state.categories[action.payload.selector].selected
    const category = {
      ...state.categories[selector],
      selected: selected
    }

    Object.keys(category.children).forEach((child) => {
      category.children[child].selected = selected
    })

    return category
  }

  switch (action.type) {
    case 'TOGGLE_CATEGORY':
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.selector]: toggleCategory(action.payload.selector)
        }
      }
    case 'TOGGLE_CATEGORY_ELEMENT':
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.payload.category]: {
            ...state.categories[action.payload.category],
            children: toggleElement(action.payload.category, action.payload.selector)
          }
        }
      }
    case 'TOGGLE_FILTERING':
      return {
        ...state,
        filter: !state.filter
      }
    case 'TOGGLE_HIGHTLIGHTING':
      return {
        ...state,
        highlight: !state.highlight
      }
    default:
      return state.categories
  }
}

export default Reducer
