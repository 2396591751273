import React from 'react'
import PropTypes from 'prop-types'

import { Header } from 'semantic-ui-react'

export default function Education (
  { title, centre, place, startDate, endDate }) {
  return (
    <>
      <Header className='education-item'>
        {centre}
        <Header.Subheader>{title}, {startDate} - {endDate}</Header.Subheader>
        <Header.Subheader>{place}</Header.Subheader>
      </Header>
    </>)
};

Education.propTypes = {
  title: PropTypes.string,
  centre: PropTypes.string,
  place: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string
}
