
const languages = {
  c: {
    label: 'C',
    selector: 'c',
    selected: false
  },
  cpp: {
    label: 'C++',
    selector: 'cpp',
    selected: false
  },
  perl: {
    label: 'perl',
    selector: 'perl',
    selected: false
  },
  python: {
    label: 'python',
    selector: 'python',
    selected: false
  },
  php: {
    label: 'PHP',
    selector: 'php',
    selected: false
  },
  ruby: {
    label: 'Ruby',
    selector: 'ruby',
    selected: false
  },
  javascript: {
    label: 'JavaScript',
    selector: 'javascript',
    selected: false
  }
}

const tools = {
  angular: {
    label: 'AngularJS',
    selector: 'angular',
    selected: false
  },
  ansible: {
    label: 'Ansible',
    selector: 'ansible',
    selected: false
  },
  apache: {
    label: 'Apache',
    selector: 'apache',
    selected: false
  },
  awlambda: {
    label: 'Amazon Lambda',
    selector: 'awlambda',
    selected: false
  },
  aws: {
    label: 'Amazon Web Services',
    selector: 'aws',
    selected: false
  },
  bluez: {
    label: 'BlueZ',
    selector: 'bluez',
    selected: false
  },
  bootstrap: {
    label: 'Bootstrap',
    selector: 'bootstrap',
    selected: false
  },
  celery: {
    label: 'Celery',
    selector: 'celery',
    selected: false
  },
  chef: {
    label: 'Chef',
    selector: 'chef',
    selected: false
  },
  connexion: {
    label: 'Connexion',
    selector: 'connexion',
    selected: false
  },
  d3: {
    label: 'D3',
    selector: 'd3',
    selected: false
  },
  datadog: {
    label: 'Datadog',
    selector: 'datadog',
    selected: false
  },
  django: {
    label: 'Django',
    selector: 'django',
    selected: false
  },
  flask: {
    label: 'Flask',
    selector: 'flask',
    selected: false
  },
  gcp: {
    label: 'Google Cloud Platform',
    selector: 'gcp',
    selected: false
  },
  gitlab: {
    label: 'Gitlab',
    selector: 'gitlab',
    selected: false
  },
  gdocs: {
    label: 'Google Docs',
    selector: 'gdocs',
    selected: false
  },
  jira: {
    label: 'Jira',
    selector: 'jira',
    selected: false
  },
  jquery: {
    label: 'jQuery',
    selector: 'jquery',
    selected: false
  },
  kubernetes: {
    label: 'Kubernetes',
    selector: 'kubernetes',
    selected: false
  },
  mstation: {
    label: 'MicroStation',
    selector: 'microstation',
    selected: false
  },
  monit: {
    label: 'Monit',
    selector: 'monit',
    selected: false
  },
  munin: {
    label: 'Munin',
    selector: 'munin',
    selected: false
  },
  mysql: {
    label: 'MySQL',
    selector: 'mysql',
    selected: false
  },
  nagios: {
    label: 'Nagios',
    selector: 'nagios',
    selected: false
  },
  nginx: {
    label: 'NGINX',
    selector: 'nginx',
    selected: false
  },
  node: {
    label: 'Node.js',
    selector: 'node',
    selected: false
  },
  nwjs: {
    label: 'NW.js',
    selector: 'nwjs',
    selected: false
  },
  plesk: {
    label: 'Plesk',
    selector: 'plesk',
    selected: false
  },
  postgres: {
    label: 'PostgreSQL',
    selector: 'postgres',
    selected: false
  },
  pthreads: {
    label: 'POSIX threads',
    selector: 'pthreads',
    selected: false
  },
  pyelliptic: {
    label: 'PyElliptic',
    selector: 'pyelliptic',
    selected: false
  },
  pytest: {
    label: 'pytest',
    selector: 'pytest',
    selected: false
  },
  qgis: {
    label: 'QGIS',
    selector: 'qgis',
    selected: false
  },
  qt: {
    label: 'Qt',
    selector: 'qt',
    selected: false
  },
  leaflet: {
    label: 'leaflet',
    selector: 'leaflet',
    selected: false
  },
  redis: {
    label: 'Redis',
    selector: 'redis',
    selected: false
  },
  sentry: {
    label: 'Sentry',
    selector: 'sentry',
    selected: false
  },
  stac: {
    label: 'STAC',
    selector: 'stac',
    selected: false
  },
  terraform: {
    label: 'Terraform',
    selector: 'terraform',
    selected: false
  },
  titanium: {
    label: 'Titanium',
    selector: 'titanium',
    selected: false
  },
  twisted: {
    label: 'Twisted',
    selector: 'twisted',
    selected: false
  },
  vue: {
    label: 'VUE',
    selector: 'vue',
    selected: false
  },
  virtuozzo: {
    label: 'Virtuozzo',
    selector: 'virtuozzo',
    selected: false
  },
  wordpress: {
    label: 'WordPress',
    selector: 'wordpress',
    selected: false
  },
  xen: {
    label: 'Xen',
    selector: 'xen',
    selected: false
  }
}

const domains = {
  consultancy: {
    label: 'Consultancy',
    selector: 'consultancy',
    selected: false
  },
  cooking: {
    label: 'Cooking',
    selector: 'cooking',
    selected: false
  },
  education: {
    label: 'Education',
    selector: 'education',
    selected: false
  },
  environment: {
    label: 'Environment',
    selector: 'environment',
    selected: false
  },
  finance: {
    label: 'Finance',
    selector: 'finance',
    selected: false
  },
  gis: {
    label: 'Geographic Information Systems',
    selector: 'gis',
    selected: false
  },
  hosting: {
    label: 'Hosting',
    selector: 'hosting',
    selected: false
  },
  marketing: {
    label: 'Marketing',
    selector: 'marketing',
    selected: false
  },
  messaging: {
    label: 'Messaging',
    selector: 'messaging',
    selected: false
  },
  planning: {
    label: 'Planning',
    selector: 'planning',
    selected: false
  },
  travel: {
    label: 'Travel',
    selector: 'travel',
    selected: false
  },
  topography: {
    label: 'Topography',
    selector: 'topography',
    selected: false
  }
}

const areas = {
  programming: {
    label: 'Programming',
    selector: 'programming',
    selected: false
  },
  sysadmin: {
    label: 'System Administration',
    selector: 'sysadmin',
    selected: false
  },
  devops: {
    label: 'Development Operations',
    selector: 'devops',
    selected: false
  },
  tl: {
    label: 'Team Leading',
    selector: 'tl',
    selected: false
  },
  pm: {
    label: 'Product Management',
    selector: 'pm',
    selected: false
  },
  lawyer: {
    label: 'Policy Writer',
    selector: 'lawyer',
    selected: false
  },
  guide: {
    label: 'Spiritual Guide',
    selector: 'guide',
    selected: false
  }
}

const os = {
  linux: {
    label: 'GNU/Linux',
    selector: 'linux',
    selected: false
  },
  debian: {
    label: 'Debian',
    selector: 'debian',
    selected: false
  },
  windows: {
    label: 'Windows',
    selector: 'windows',
    selected: false
  },
  wserver: {
    label: 'Windows Server',
    selector: 'wserver',
    selected: false
  },
  macos: {
    label: 'MacOS',
    selector: 'macos',
    selected: false
  }
}

export const initialConfig = {
  filter: false,
  highlight: false,
  categories: {
    language: {
      label: 'Language',
      selector: 'language',
      selected: false,
      children: languages
    },
    tool: {
      label: 'Tool',
      selector: 'tool',
      selected: false,
      children: tools
    },
    domain: {
      label: 'Domain',
      selector: 'domain',
      selected: false,
      children: domains
    },
    area: {
      label: 'Area',
      selector: 'area',
      selected: false,
      children: areas
    },
    os: {
      label: 'Operative System',
      selector: 'os',
      selected: false,
      children: os
    }
  }
}
