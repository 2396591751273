import React from 'react'
import PropTypes from 'prop-types'

export default function Action (
  { tools, areas, children }) {
  return (
        <li className='action-item'>
        { children }
        </li>)
};

Action.propTypes = {
  areas: PropTypes.arrayOf(PropTypes.string),
  tools: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
