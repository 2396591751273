import React from 'react'

import Experience from './components/Experience'
import Action from './components/Action'
import Tool from './components/Tool'
import OS from './components/OS'
import Language from './components/Language'
import Education from './components/Education'

export default function CV () {
  return (
        <>
            <Experience
                company="Tesselo"
                role="Head of Programming"
                place="Lisbon, Portugal"
                startDate="April 2021"
                endDate="November 2022"
                domains={['gis', 'environment', 'geospatial']}
                areas={['programming', 'tl', 'pm', 'devops', 'lawyer', 'datascience']}
            >
                <Action
                    tools={['datadog', 'sentry', 'slack', 'github']}
                    areas={['devops', 'programming', 'lawyer']}>
                    Set up, configured, and created policies for error awareness, monitoring and code
                    quality using <Tool name="datadog"/>, <Tool name="sentry"/>, <Tool name="slack"/> integrations
                    and automated CI checks.
                </Action>
                <Action
                    tools={['slab', 'asana']}
                    areas={['lawyer', 'tl', 'guide']}>
                    Led the restructuring of the organization management with the inclusion of rituals, workflows,
                    meetings structure, proposals and agreement processes.
                </Action>
                 <Action
                    tools={['philosophy', 'slab']}
                    areas={['guide']}>
                    Hosted regular Philosophy Club meetings to orient the team and discuss the meaning of
                    programming, theory building, ethics and other related topics.
                </Action>
                <Action
                    tools={['python', 'keras', 'stac', 'postgres']}
                    areas={['programming', 'datascience']}>
                    Refactored and improved the main <Language name="python"/> platform
                    using <Tool name='keras'/>, <Tool name='stac'/> to add features, improve significance,
                    encapsulation and decrease redundancy.
                </Action>
                <Action
                    tools={['keras', 'python', 'gdal', 'qgis']}
                    areas={['datascience', 'programming']}>
                    Created deep learning <Tool name='keras'/> models for
                    tree height vegetation management using and customizing the Tesselo platform, as well as
                    realizing postprocessing tasks on the predictions using <Tool name='gdal'/> and <Tool name='qgis'/>.
                </Action>
                <Action
                    tools={['python', 'asana', 'slab', 'postgres']}
                    areas={['pm', 'devops', 'pm']}>
                    Developed a <Language name="python"/> service integrating <Tool name="asana"/>, <Tool
                    name="slack"/> and zoom to keep track of the performance of the
                    team and aid with the current assigned tasks and guide through the different rituals.
                </Action>
                <Action
                    tools={['javascript', 'aws', 'awlambda', 'cognito']}
                    areas={['programming', 'pm']}>
                    Created a service using <Language name="javascript"/>, <Tool name='awlambda'/>, <Tool
                    name='oauth'/> and optionally <Tool name='cognito'/> to unify
                    authentication across services.
                </Action>
                <Action
                    tools={['javascript', 'vue', 'leaflet']}
                    areas={['programming']}>
                    Collaborated in the development of a <Tool name='VUE'/>+<Tool name='leaflet'/> frontend application.
                </Action>

            </Experience>
            <Experience
                company="Preply"
                role="Technical Lead"
                place="Barcelona, Spain"
                startDate="October 2020"
                endDate="December 2020"
                domains={['education', 'finance']}
                areas={['programming', 'devops', 'lawyer']}
            >

                <Action
                    tools={['rfc', 'gdocs']}
                    areas={['lawyer']}>
                    Co-introduced and promoted RFC process and enforced an open and
                    collaborative decision making culture.
                </Action>
                <Action
                    tools={['sentry', 'terraform', 'datadog']}
                    areas={['devops', 'lawyer']}>
                    Integrated <Tool name="sentry"/> and <Tool name="terraform"/> and proposed an error awareness
                    policy.
                </Action>
                <Action
                    tools={['datadog']}
                    areas={['devops', 'lawyer']}>
                    Introduced metrics taxonomy to link system and business operations for the
                    payments domain and created a dashboard for the matter in <Tool name="datadog"/>.
                </Action>
                <Action
                    tools={['gdocs']}
                    areas={['lawyer', 'devops']}>
                    Wrote guidelines and continuous integration steps to achieve a clear code
                    ownership and embrace the SOLID principles.
                </Action>
                <Action
                    tools={['python', 'django']}
                    areas={['programming']}>
                    Refactored several parts of a <Tool name="django"/> application to unify and make explicit the
                    manipulation of domain logic related with payments and refunds.
                </Action>
            </Experience>

            <Experience
                company="Kiwi.com"
                role="Technical Team Lead"
                place="Barcelona, Spain"
                startDate="March 2019"
                endDate="September 2020"
                domains={['travel', 'finance']}
                areas={['devops', 'tl', 'pm', 'lawyer', 'guide']}
            >

                <Action
                    tools={[]}
                    areas={['tl', 'lawyer']}>
                    Built a team to support developing effective solutions for mission critical
                    problems.
                </Action>
                <Action
                    tools={['jira']}
                    areas={['pm', 'tl']}>
                    Embraced STATIK (a Kanban approach) and evolutionary management to
                    improve team delivery, communication, cohesion and well-being.
                </Action>
                <Action
                    tools={[]}
                    areas={['tl']}>
                    Strong focus on using the tools and techniques that the team will love and
                    adopt.
                </Action>
                <Action
                    tools={[]}
                    areas={['pm', 'lawyer']}>
                    Ventured in product manager and architect responsibilities for proposing
                    and defining solutions for problems that involve several and originally lightly
                    related parts of the company.
                </Action>
                <Action
                    tools={['gdocs']}
                    areas={['lawyer']}>
                    Authored the first meta RFC in the company to define a process in order
                    to maximize the success of adoption after a decision making document is
                    approved.
                </Action>
                <Action
                    tools={['bbq']}
                    areas={['tl']}>
                    Conceived and organized a team boot camp in a castle to bring up the team
                    together, focus on engineering + responsibilities and watch the mountains
                    while having a barbecue.
                </Action>
            </Experience>

            <Experience
                company="Kiwi.com"
                role="Backend Developer"
                place="Barcelona, Spain"
                startDate="April 2018"
                endDate="September 2020"
                domains={['travel', 'finance']}
                areas={['programming', 'devops']}
            >

                <Action
                    tools={['python', 'flask', 'connexion', 'aiohttp', 'postgres', 'pytest', 'celery']}
                    areas={['programming']}>
                    Developed from scratch and from monolith some micro services using <Language name="python"/>
                    , <Tool name="flask"/>, <Tool name="connexion"/>, <Tool name="aiohttp"/>, <Tool name="postgres"/>.
                </Action>
                <Action
                    tools={['python', 'gitlab', 'awlambda']}
                    areas={['devops']}>
                    Built a continuous deployment serverless service and configuration, small but
                    critical for business with <Language name="python"/>, <Tool name="gitlab"/> and
                    <Tool name="awlambda"/>.
                </Action>
                <Action
                    tools={[]}
                    areas={['lawyer']}>
                    Spotted and proposed data quality improvements when cooperating with
                    other teams and departments.
                </Action>
                <Action
                    tools={['aws', 'gcp']}
                    areas={['devops', 'sysadmin']}>
                    Assisted migration from <Tool name="aws"/> to <Tool name="gcp"/> clouds, applications and data.
                </Action>
                <Action
                    tools={['terraform', 'kubernetes']}
                    areas={['devops']}>
                    Used <Tool name="terraform"/> and <Tool name="kubernetes"/> configuration files and tools to define,
                    deploy
                    and manage services.
                </Action>
                <Action
                    tools={['cake']}
                    areas={[]}>
                    Baked a <Tool name="cake"/> (almost) every week.
                </Action>
            </Experience>

            <Experience
                company="grouptime GmbH"
                role="Software Engineer"
                place="Frankfurt, Germany"
                startDate="May 2015"
                endDate="June 2017"
                domains={['messaging']}
                areas={['pm', 'programming']}>

                <Action
                    tools={['node', 'titanium', 'android', 'ios']}
                    areas={['programming']}>
                    Built a common framework that handles models, server communication and
                    API data processing on <Tool name="node"/>, <Tool name="titanium"/> for <OS name="android"/>
                    and <OS name="ios"/>.
                </Action>
                <Action
                    tools={['windows', 'macos', 'linux', 'nwjs', 'angular',
                      'mocha', 'istanbul']}
                    areas={['programming', 'pm']}>
                    Developed the desktop client for <OS name="windows"/>, <OS name="macos"/>
                    and <OS name="linux"/> using <Tool name="nwjs"/>,
                    <Tool name="angular"/>, <Tool name="sass"/>, <Tool name="mocha"/>,
                    <Tool name="istanbul"/>.
                </Action>
                <Action
                    tools={['python', 'pyelliptic']}
                    areas={['programming']}>
                    Modified the <Language name="python"/> library <Tool name="pyelliptic"/> to comply with a subset of
                    the ANSI
                    X9.62 standard.
                </Action>
                <Action
                    tools={['python', 'pyelliptic', 'nosetest', 'node', 'titanium']}
                    areas={['programming']}>
                    Designed the key exchange protocol (ECDHE) and implemented it in the
                    server and the desktop client.
                </Action>
                <Action
                    tools={['python', 'pyelliptic', 'nosetest', 'django', 'celery', 'redis', 'twisted',
                      'autobahn', 'node', 'titanium']}
                    areas={['programming']}>
                    Implemented the client and server side solution for Desktop notifications
                    that interoperated with the <Tool name="django"/> backend using <Tool name="celery"/> tasks,
                    <Tool name="autobahn"/> and <Tool name="twisted"/>.
                </Action>
                <Action
                    tools={['node', 'titanium']}
                    areas={['programming']}>
                    Fixed security bugs and contributed to
                    several <Tool name="titanium"/> and <Tool name="node"/> packages.
                </Action>
                <Action
                    tools={['python', 'pyelliptic', 'nosetest', 'django', 'celery', 'redis', 'ansible',
                      'docker']}
                    areas={['programming', 'lawyer']}>
                    Contributed in the protocol and API design, backend development and
                    management using <Tool name="nosetest"/>, <Language name="django"/>, <Tool name="celery"/>,
                    <Tool name="redis"/>, <Tool name="ansible"/>, <Tool name="docker"/>.
                </Action>
            </Experience>

            <Experience
                company="FoodRocket Inc."
                role="Lead Engineer"
                place="New York, USA"
                startDate="October 2013"
                endDate="April 2015"
                domains={['cooking', 'planning']}
                areas={['devops', 'programming', 'pm', 'sysadmin']}>

                <Action
                    tools={['chef', 'aws']}
                    areas={['devops', 'sysadmin']}>
                    Architected, provisioned, configured, deployed, and maintained a multi-role
                    system using <Tool name="chef"/> and <Tool name="aws"/>.
                </Action>
                <Action
                    tools={['ruby', 'puma', 'sinatra', 'mysql', 'debian', 'angular']}
                    areas={['programming', 'pm']}>
                    Built a jruby/<Tool name="puma"/>/<Tool name="sinatra"/>/<Tool name="mysql"/> back-end on <OS
                    name="debian"/>
                    implementing a RESTful API to support a Chrome extension and two different <Tool
                    name="angular"/> front-
                    end MVC clients.
                </Action>
                <Action
                    tools={['chrome', 'javascript', 'jquery']}
                    areas={['programming', 'pm']}>
                    Built a Google Chrome extension using <Language name="javascript"/>/<Tool name="jquery"/> to examine
                    Pinterest’s DOM, detect pins corresponding to food recipes using a variety of
                    heuristics, and inject the pins with custom hooks to trigger FoodRocket’s app
                    overlay.
                </Action>
                <Action
                    tools={['ruby', 'anemone', 'thor', 'redis']}
                    areas={['programming']}>
                    Implemented two focused crawlers using <Language name="ruby"/>/<Tool name="anemone"/>/<Tool
                    name="thor"/>,
                    with <Tool name="redis"/> as a queue.
                </Action>
                <Action
                    tools={['nagios', 'munin', 'monit']}
                    areas={['sysadmin']}>
                    Instrumented and monitored servers with <Tool name="nagios"/>, <Tool name="munin"/>, and <Tool
                    name="monit"/>.
                </Action>
                <Action
                    tools={['gitlab']}
                    areas={['devops']}>
                    Set up a hosted <Tool name="gitlab"/> server for version control and documentation
                </Action>
            </Experience>

            <Experience
                company="UNED"
                role="Adjunct Instructor (part-time)"
                place="Madrid, Spain"
                startDate="October 2013"
                endDate="October 2014"
                domains={['education']}
                areas={['lawyer', 'programming']}>

                <Action
                    tools={[]}
                    areas={['education']}>
                    Designed syllabus and assignments for distance-learning graduate-level
                    course titled “Apps/Web & Marketing&quot;.
                </Action>
                <Action
                    tools={['ruby', 'sinatra', 'padrino', 'angular', 'mysql', 'linux']}
                    areas={['programming', 'sysadmin']}>
                    Built course and sample websites for instructional purposes using <Language name="ruby"/>/
                    <Tool name="sinatra"/>/<Tool name="padrino"/>, <Tool name="angular"/>, <Tool name="mysql"/>,
                    <OS name="linux"/> on a dedicated virtual private server (VPS).
                </Action>
            </Experience>

            <Experience
                company="Photonbit"
                role="Full-Stack Developer (Freelance)"
                place="Madrid, Spain"
                startDate="February 2013"
                endDate="March 2014"
                domains={['consultancy']}
                areas={['programming', 'pm', 'sysadmin']}>

                <Action
                    tools={['php', 'lightvc', 'coughphp', 'javascript', 'jquery', 'bootstrap', 'angular']}
                    areas={['programming', 'pm']}>
                    Built a custom Content Management Systems (CMS) using <Language name="php"/> micro-
                    frameworks <Tool name="phalcon"/> and <Tool name="lightvc"/>/<Tool name="coughphp"/> (back-end),
                    <Language name="javascript"/>/<Tool name="jquery"/> (public front-end),
                    and <Tool name="bootstrap"/>/<Tool name="angular"/> (admin front-end).
                </Action>
                <Action
                    tools={['java', 'aws', 'bigbluebutton']}
                    areas={['sysadmin']}>
                    Integrated a CMS with a video conference third-party (<Language name="java"/>/JSP/
                    <Tool name="bigbluebutton"/>) running on <Tool name="aws"/>/<Tool name="ec2"/>.
                </Action>
                <Action
                    tools={['wordpress', 'php']}
                    areas={['programming']}>
                    Developed and integrated various <Tool name="wordpress"/> plugins using social APIs
                    (Twitter, Facebook and Google+), image processing (Imagick), and content
                    classification via regex.
                </Action>
                <Action
                    tools={['cpp', 'qt']}
                    areas={['programming', 'pm']}>
                    Built cross-platform media player kiosk software using <Language name="cpp"/>/<Tool name="qt"/>
                </Action>
            </Experience>

            <Experience
                company="Metodo White"
                role="Lead Engineer"
                place="Madrid, Spain"
                startDate="September 2012"
                endDate="December 2012"
                domains={['marketing']}
                areas={['tl', 'programming', 'sysadmin']}>

                <Action
                    tools={[]}
                    areas={['tl']}>
                    Supervised a team of 5 developers
                </Action>
                <Action
                    tools={['debian', 'subversion']}
                    areas={['devops', 'sysadmin']}>
                    Managed and administered dev, stage, and prod environments using <OS name="debian"/>
                    VPS, <Tool name="subversion"/> hooks and cron tasks
                </Action>
                <Action
                    tools={['php', 'javascript', '']}
                    areas={['programming']}>
                    Built and published promotional Facebook apps using <Language name="php"/> and <Language
                    name="javascript"/>.
                </Action>
                <Action
                    tools={['wordpress', 'php', 'javascript', 'jquery']}
                    areas={['programming']}>
                    Build <Tool name="wordpress"/> themes from minimal, responsive templates and
                    incorporated custom business logic, back-office modifications, geopositioning
                    and Google Maps technologies in both server/<Language name="php"/> and client/<Language
                    name="javascript"/>/
                    jQuery.
                </Action>
                <Action
                    tools={['javascript', 'd3']}
                    areas={['programming']}>
                    Implemented dynamic and user responsive data representation using
                    <Language name="javascript"/>/<Tool name="d3"/>
                </Action>
            </Experience>

            <Experience
                company="Hispaweb"
                role="Senior System Administrator"
                place="Madrid, Spain"
                startDate="May 2012"
                endDate="October 2012"
                domains={['hosting']}
                areas={['sysadmin', 'programming']}>

                <Action
                    tools={['centos', 'linux', 'wserver', 'parallels', 'virtuozzo', 'plesk']}
                    areas={['sysadmin']}>
                    Installed, configured and managed a heterogeneous network of 200
                    production servers running <OS name="centos"/>, <OS name="wserver"/> and Parallels
                    Business Automation Suite with <Tool name="virtuozzo"/> and Plesk.
                </Action>
                <Action
                    tools={['']}
                    areas={['sysadmin']}>
                    Resolved mission-critical service problems.
                </Action>
                <Action
                    tools={['xen', 'kvm', 'cloudplatform', 'cloudstack', 'netapp']}
                    areas={['sysadmin']}>
                    Installed and deployed Cloud
                    Orchestration (<Tool name="cloudplatform"/>) from the ground up: hardware selection (HP
                    Proliant, NetApp), network topology, hypervisors (<Tool name="xen"/>, XenServer and KVM)
                    and orchestrator/gui (CloudStack/CloudPlatform).
                </Action>
                <Action
                    tools={['owncloud', 'php', 'linux']}
                    areas={['programming']}>
                    Extended file sync and share software server (<Tool name="owncloud"/>/<Language name="php"/>/<OS
                    name="linux"/>)
                    to add group and roles support.
                </Action>
                <Action
                    tools={['cpp', 'qt', 'windows', 'linux', 'macos']}
                    areas={['programming']}>
                    Modified ownCloud software clients (<Language name="cpp"/>/<Tool name="qt"/>/<OS name="windows"/>,
                    <OS name="linux"/>, <OS name="macos"/>) to support themes and fixed server support.
                </Action>
                <Action
                    tools={['php', 'parallels']}
                    areas={['programming']}>
                    Wrote a library in <Language name="php"/> to add automatic ESNIC registrar support to Parallels
                    Business Automation Suite.
                </Action>
                <Action
                    tools={['']}
                    areas={['tl']}>
                    Mentored and trained team members to solve increasingly complex tasks
                </Action>
            </Experience>

            <Experience
                company="comvive"
                role="System Administrator"
                place="Seville, Spain"
                startDate="November 2008"
                endDate="April 2012"
                domains={['hosting']}
                areas={['sysadmin']}>

                <Action
                    tools={['apache', 'lighttpd', 'nginx', 'mysql', 'linux', 'qmail', 'mydns', 'postgres']}
                    areas={['sysadmin']}>
                    Installed, configured and managed services on 600+ independent servers,
                    including web (<Tool name="apache"/>, <Tool name="lighttpd"/>, <Tool name="nginx"/>),
                    database (<Tool name="mysql"/>, <Tool name="postgres"/>), mail (<Tool name="qmail"/>),
                    DNS (<Tool name="mydns"/>), other services (<Tool name="tomcat"/>, Red5, Sphinx), and platforms
                    (Dokeos, Chamilo, BigBluebutton).
                </Action>
                <Action
                    tools={['bash', 'expect', 'perl']}
                    areas={['sysadmin', 'devops']}>
                    Automated server access, authentication, installation and update using bash,
                    <Tool name="expect"/> and <Language name="perl"/>.
                </Action>
                <Action
                    tools={['xen', 'debian', 'linux']}
                    areas={['sysadmin']}>
                    Installed and deployed VPS with <Tool name="xen"/> hypervisor, Dell EqualLogic, <OS name="debian"/>
                    <OS name="linux"/> and custom scripts to automate configuration.
                </Action>
                <Action
                    tools={[]}
                    areas={['sysadmin']}>
                    Optimized application and database performance for high traffic sites.
                </Action>
                <Action
                    tools={['bacula']}
                    areas={['sysadmin']}>
                    Deployed centralized and redundant backup system (<Tool name="bacula"/>).
                </Action>
                <Action
                    tools={['nagios', 'snmp', 'mrtg', 'cacti']}
                    areas={['sysadmin']}>
                    Monitored and responded to service alerts triggered by <Tool name="nagios"/>, <Tool name="snmp"/>,
                    <Tool name="mrtg"/> and <Tool name="cacti"/>.
                </Action>
                <Action
                    tools={[]}
                    areas={[]}>
                    Documented and evangelized problem-solving guidelines and best practices.
                </Action>
            </Experience>

            <Experience
                company="Grupo ID Innova"
                role="Programmer"
                place="Seville, Spain"
                startDate="December 2007"
                endDate="November 2008"
                domains={['marketing']}
                areas={['programming', 'pm', 'sysadmin']}>

                <Action
                    tools={['c', 'python', 'bash', 'perl']}
                    areas={['programming', 'pm']}>
                    Designed and implemented complete Bluetooth marketing system.
                </Action>
                <Action
                    tools={['linux', 'bash']}
                    areas={['programming']}>
                    Minimized memory consumption by crafting a <OS name="linux"/> initramfs.
                </Action>
                <Action
                    tools={['c']}
                    areas={['programming']}>
                    Modified device firmware to encrypt all saved data.
                </Action>
                <Action
                    tools={['python']}
                    areas={['programming']}>
                    Developed heuristics to detect phone brand and model based on bluetooth
                    footprint using <Language name="python"/>.
                </Action>
                <Action
                    tools={['c', 'pthreads']}
                    areas={['programming']}>
                    Programmed a concurrent Bluetooth detector and
                    media dispatcher capable of taking advantage of N(N{'>'}1) Bluetooth devices
                    using <Language name="c"/> and <Tool name="pthreads"/>.
                </Action>
                <Action
                    tools={['linux', 'bluez', 'c']}
                    areas={['programming']}>
                    Modified low level <OS name="linux"/> library (Bluez) to support variable timeout on
                    Bluetooth communications.
                </Action>
                <Action
                    tools={['perl']}
                    areas={['programming', 'sysadmin']}>
                    Built a data synchronization service between dispatcher devices and server.
                </Action>
                <Action
                    tools={['php']}
                    areas={['programming']}>
                    Programmed server-side synchronization service and user interface using <Language name="php"/>.
                </Action>
            </Experience>

            <Experience
                company="Quattro S.L."
                role="Programmer"
                place="Cádiz, Spain"
                startDate="April 2005"
                endDate="September 2007"
                domains={['topography', 'gis']}
                areas={['programming']}>

                <Action
                    tools={['cpp', 'visualbasic']}
                    areas={['programming']}>
                    Fully-automated a previously manual information flow from field workers
                    to topographer and from topographer to city council Geographic Information
                    System (GIS).
                </Action>
                <Action
                    tools={['pocketpc', 'embeddedvisual', 'cpp']}
                    areas={['programming']}>
                    Programmed a <OS name="pocketpc"/> app to make data acquisition and representation
                    using <Tool name="embeddedvisual"/> <Language name="cpp"/>.
                </Action>
                <Action
                    tools={['visualbasic']}
                    areas={['programming']}>
                    Developed desktop software with custom graphic widgets for <Tool name="visualbasic"/>
                </Action>
                <Action
                    tools={['mdl', 'c', 'microstation']}
                    areas={['programming']}>
                    Wrote software to perform analysis, reconstruction and representation of
                    flow networks using MicroStation Development Language (MDL, <Language name="c"/> with
                    mathematics, graphics and database libraries).
                </Action>
                <Action
                    tools={['java', 'swing']}
                    areas={['programming']}>
                    Developed business management applications using <Language name="java"/>/<Tool name="swing"/>.
                </Action>
            </Experience>

            <Education
                title="Python Data Science Professional Certificate"
                centre="IBM"
                place="Barcelona, Spain"
                startDate="2020"
                endDate="2020"/>
            <Education
                title="LPIC 2, Linux System Administration"
                centre="Linux Professional Institute"
                place="Seville, Spain"
                startDate="2008"
                endDate="2009"/>
            <Education
                title="Ingeniería Técnica en Informática de Gestión"
                centre="Universidad de Cádiz"
                place="Cádiz, Spain"
                startDate="1997"
                endDate="2000"/>
            <Education
                title="BUP y COU"
                centre="Instituto Wenceslao Benítez"
                place="Cádiz, Spain"
                startDate="1994"
                endDate="1998"/>
        </>
  )
}
