import React, { useState, useRef } from 'react'
import { Button, Container, Grid, Header, Icon, Label, Rail, Ref, Sticky } from 'semantic-ui-react'

import CV from './CV'
import ConfigBox from './components/ConfigBox'
import Store from './filters/Store'

function App () {
  const [configActive, setConfigActive] = useState(false)
  const contextRef = useRef(null)

  const toggleConfig = () => {
    setConfigActive(!configActive)
  }

  const configSegments = () => {
    return configActive
      ? (
        <ConfigBox />
        )
      : undefined
  }

  return (

    <Store>
    <Container style={{ padding: '2em 0em' }}>
        <Ref innerRef={contextRef}>
        <Grid container stackable>
            <Grid.Column>
                <Container text id='theCV'>
                    <Header as='h1'>Daniel Moreno Medina</Header>
                    <Header as='h2' data-html2canvas-ignore='true'>Work Story Composer</Header>
                    <Container data-html2canvas-ignore='true'>
                        Hello human! Welcome to this particular place. You can read my entire work story or you can go
                        to the configuration part on the right to highlight or filter by the relevant programming
                        languages, tools or domains you want to see.
                    </Container>
                    <Container style={{ padding: '2em 0em' }}>
                            <CV />
                    </Container>
                    <Container>

                        <Label as='a' href='https://cv.photonbit.es' floated='left'>
                            <Icon name='globe' /> https://cv.photonbit.es
                        </Label>
                        <Label as='a' href='mailto:dani@photonbit.es' floated='right'>
                            <Icon name='mail' /> dani@photonbit.es
                        </Label>
                    </Container>
                </Container>
                <Container>
                <Rail internal attached position='right'>
                    <Sticky context={contextRef}>
                        <Button
                          icon='configure'
                          style={{ position: 'absolute', right: '0', top: '4em' }}
                          toggle
                          active={configActive}
                          onClick={toggleConfig} />
                        {configSegments()}
                    </Sticky>
                </Rail>
                </Container>
            </Grid.Column>
        </Grid>
        </Ref>
    </Container>
    </Store>
  )
}

export default App
