import React, { useContext, useState } from 'react'
import { Accordion, Button, Checkbox, Divider, Icon, Segment } from 'semantic-ui-react'

import { Context } from '../filters/Store'

export default function ConfigBox () {
  const [state, dispatch] = useContext(Context)
  const [activeIndex, setActive] = useState(-1)

  function drawCategory (entry, index, array) {
    const [, item] = entry

    function handleClick (e, propos) {
      // What is this, jquery?
      if (e.target.localName !== 'label') {
        const newIndex = activeIndex === index ? -1 : index
        setActive(newIndex)
      }
    }

    return (
            <>
                <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={handleClick}
                >
                    <Icon name='dropdown'/>
                    {item.label}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                    {Object.entries(item.children).map(drawCategoryElement, item)}
                </Accordion.Content>
            </>
    )
  }

  function drawCategoryElement (entry, index, array) {
    const [selector, item] = entry
    const category = this.selector

    function toggleCategoryElement () {
      dispatch({ type: 'TOGGLE_CATEGORY_ELEMENT', payload: { category: category, selector: selector } })
    }

    return (
            <Checkbox label={item.label} key={item.label} checked={item.selected} onChange={toggleCategoryElement}
                      style={{ padding: '0.5em' }}/>
    )
  }

  function toggleFiltering () {
    dispatch({ type: 'TOGGLE_FILTERING' })
  }

  function toggleHighlighting () {
    dispatch({ type: 'TOGGLE_HIGHTLIGHTING' })
  }

  return (
        <Segment style={{ marginTop: '100px' }} >
            <Button.Group>
                <Button toggle active={state.highlight} onClick={toggleHighlighting}>Highlight</Button>
                <Button toggle active={state.filter} onClick={toggleFiltering}>Filter</Button>
            </Button.Group>
            <Divider/>
            <Accordion styled>
                {Object.entries(state.categories).map(drawCategory)}
            </Accordion>
        </Segment>
  )
};
