import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Header, Transition } from 'semantic-ui-react'
import { deepForEach } from 'react-children-utilities'

import { Context } from '../filters/Store'

export default function Experience (
  { company, role, place, startDate, endDate, areas, domains, lore, children }) {
  const [scores, setScores] = useState([])
  const [score, setScore] = useState(0)
  const [showLore, setShowLore] = useState(false)
  const [state] = useContext(Context)

  function computeCurrentScore () {
    let finalScore = 0

    Object.values(state.categories).forEach((category) => {
      Object.values(category.children).forEach((element) => {
        if (element.selected && scores[element.selector]) {
          finalScore += scores[element.selector]
        }
      })
    })

    setScore(finalScore)
  }

  /* Only when the site loads, scores are static */
  function computeScores () {
    const scores = {}

    deepForEach(children, (child) => {
      const name = child?.props?.name
      if (name) {
        if (scores[name]) {
          scores[name] += 1
        } else {
          scores[name] = 1
        }
      }
    })

    if (areas) {
      areas.forEach((area) => {
        if (scores[area]) {
          scores[area] += 1
        } else {
          scores[area] = 1
        }
      })
    }

    if (domains) {
      domains.forEach((domain) => {
        if (scores[domain]) {
          scores[domain] += 1
        } else {
          scores[domain] = 1
        }
      })
    }

    children.forEach((child) => {
      const childAreas = child.props?.areas
      const childTools = child.props?.tools

      if (childAreas) {
        childAreas.forEach((area) => {
          if (scores[area]) {
            scores[area] += 1
          } else {
            scores[area] = 1
          }
        })
      }

      if (childTools) {
        childTools.forEach((tool) => {
          if (scores[tool]) {
            scores[tool] += 1
          } else {
            scores[tool] = 1
          }
        })
      }
    })

    setScores(scores)
  }

  useEffect(() => {
    computeScores()
  }, [])

  useEffect(() => {
    computeCurrentScore()
  }, [state])

  function loreClick () {
    setShowLore(!showLore)
  }

  function loreSwitch () {
    return lore
      ? (
        <Button basic animated='vertical' onClick={loreClick} style={{ float: 'right' }}>
          <Button.Content hidden>Lore</Button.Content>
          <Button.Content visible>
            <Icon name='book' />
          </Button.Content>
        </Button>
        )
      : null
  }

  if (state.filter && !score) {
    return null
  } else {
    return (
            <div className='experience-item page-break'>
                <Header><p>{role} @ {company} { loreSwitch() }</p>
                  <Header.Subheader>{startDate} - {endDate}</Header.Subheader>
                  <Header.Subheader>{place}</Header.Subheader>
                </Header>
                <Transition visible={showLore} animation='scale' duration={500}>
                    <p>{lore}</p>
                </Transition>
                <ul>
                { children }
                </ul>
            </div>)
  }
};

Experience.propTypes = {
  company: PropTypes.string,
  role: PropTypes.string,
  place: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.string),
  tools: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
